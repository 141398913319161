import React from 'react';
import querystring from 'querystring';
import {
  getPage,
  getRedirect,
  getAllPages,
  WagtailApiResponseError,
} from '../api/wagtail';
import ContainerMap from '../containers/ContainerMap';
// import { Layout } from '../components/Layout';
import { PLASMIC } from '../plasmic-init';

const isProd = process.env.NODE_ENV === 'production';

// https://nextjs.org/docs/routing/dynamic-routes#catch-all-routes
export default function CatchAllPage({ componentName, componentProps }) {
  if (!componentName) {
    return <h1>Cannot render unnamed component</h1>;
  }

  const Component = ContainerMap[componentName];

  if (!Component) {
    return <h1>Component '{componentName}' not found</h1>;
  }

  return React.createElement(Component, componentProps);
}

// ENABLE for SSR
// https://nextjs.org/docs/basic-features/data-fetching/get-server-side-props
/*
export async function getServerSideProps({ req, params, res }) {
  let path = params?.path || [];
  path = path.join('/');

  const { host } = req.headers;
  let queryParams = new URL(req.url, `https://${host}`).search;
  if (queryParams.indexOf('?') === 0) {
    queryParams = queryParams.substr(1);
  }
  queryParams = querystring.parse(queryParams);

  // Try to serve page
  try {
    const {
      json: { componentName, componentProps, redirect, customResponse },
      headers,
    } = await getPage(path, queryParams, {
      headers: {
        cookie: req.headers.cookie,
        host,
      },
    });

    // Forward any cookie we encounter
    const cookies = headers.get('set-cookie');
    if (cookies) {
      res.setHeader('Set-Cookie', cookies);
    }

    if (customResponse) {
      const { body, body64, contentType } = customResponse;
      res.setHeader('Content-Type', contentType);
      res.statusCode = 200;
      res.write(body64 ? Buffer.from(body64, 'base64') : body);
      res.end();

      return { props: {} };
    }

    if (redirect) {
      const { destination, isPermanent } = redirect;
      return {
        redirect: {
          destination: destination,
          permanent: isPermanent,
        },
      };
    }

    return { props: { componentName, componentProps } };
  } catch (err) {
    if (!(err instanceof WagtailApiResponseError)) {
      throw err;
    }

    // When in development, show django error page on error
    if (!isProd && err.response.status >= 500) {
      const html = await err.response.text();
      return {
        props: {
          componentName: 'PureHtmlPage',
          componentProps: { html },
        },
      };
    }

    if (err.response.status >= 500) {
      throw err;
    }
  }

  // Try to serve redirect
  try {
    const { json: redirect } = await getRedirect(path, queryParams, {
      headers: {
        cookie: req.headers.cookie,
        host,
      },
    });
    const { destination, isPermanent } = redirect;
    return {
      redirect: {
        destination: destination,
        permanent: isPermanent,
      },
    };
  } catch (err) {
    if (!(err instanceof WagtailApiResponseError)) {
      throw err;
    }

    if (err.response.status >= 500) {
      throw err;
    }
  }

  // Serve 404 page
  return { notFound: true };
}
*/

// ENABLE for SSG
// https://nextjs.org/docs/basic-features/data-fetching/get-static-props
export async function getStaticProps({ params, preview, previewData }) {
  params = params || {};
  let path = params.path || []; // comes from filename, [...`path`]

  path = path.join('/');

  let { json: pageData } = await getPage(path);

  // Pass the path through to pages
  pageData.componentProps = Object.assign({}, pageData.componentProps, {
    path: `/${path}`,
  });

  // Statically fetch the data needed to render Plasmic pages or components.
  let plasmicData = {};
  if (pageData.componentName === 'PlasmicPage') {
    plasmicData = await PLASMIC.fetchComponentData(
      pageData.componentProps.path
    );
    pageData.componentProps = Object.assign({}, pageData.componentProps, {
      plasmicData,
    });
  }

  // TODO: incremental static regeneration?
  // https://nextjs.org/docs/basic-features/data-fetching/incremental-static-regeneration
  return { props: pageData };
}

// https://nextjs.org/docs/basic-features/data-fetching/get-static-paths
export async function getStaticPaths() {
  const { json: data } = await getAllPages();

  // TODO: await getAllImageRenditions(); ... ????
  // This could fetech image renditions, store and serve locally
  // Wrap with feature flag to allow 'includeImages' vs. default 'linkImages' behavior
  // https://nextjs.org/docs/basic-features/data-fetching/get-static-props

  let htmlUrls = data.items.map((x) => x.relativeUrl);

  htmlUrls = htmlUrls.filter((x) => x);
  htmlUrls = htmlUrls.map((x) => x.split('/'));
  htmlUrls = htmlUrls.map((x) => x.filter((y) => y));
  htmlUrls = htmlUrls.filter((x) => x.length);

  const paths = htmlUrls.map((x) => ({ params: { path: x } }));

  return {
    paths: paths,
    fallback: false,
  };
}
